
import { defineComponent } from 'vue';
import { GoogleMap, Marker } from 'vue3-google-map';
import globals from '../../helpers/globals';
import FormButton from '../../components/forms/Button.vue';
import SegmentDataHelper from '../../helpers/SegmentDataHelper';
import FinanceTypeDataHelper from '../../helpers/FinanceTypeDataHelper';
import ProductDataHelper from '../../helpers/ProductDataHelper';
import FileDataHelper from '../../helpers/FileDataHelper';
import FileDataService from '../../services/FileDataService';

export default defineComponent({
  name: 'End',
  mixins: [
    globals,
  ],
  components: {
    FormButton,
    GoogleMap,
    Marker,
  },
  data() {
    return {
      timeslot: {} as any,
      center: {
        lat: Number,
        lng: Number,
      },
    };
  },
  created() {
    const vm = this;

    /* Remove file so that an update could be downloaded */
    // this.$store.dispatch('setSelectedFile', {});

    this.$store.dispatch('isLoading', true);

    this.load((data: any) => {
      // Redirect user of he didnt complete RDV step
      if (data.timeslot === null) {
        vm.$router.push({
          name: 'RDV',
          params: {
            file: vm.$route.params.file,
          },
        });

        vm.$notify({
          text: 'Please book a rendez-vous before going further.',
          duration: 8000,
          ignoreDuplicates: true,
          type: 'error',
        });
      }

      vm.timeslot = data.timeslot;
      vm.center.lat = vm.timeslot.agency.lat;
      vm.center.lng = vm.timeslot.agency.long;

      vm.init();
    });
  },
  mounted() {
    /* Scroll page to top (case of mobile phones) */
    this.scrollToTop();
  },
  methods: {
    /* COMPONENT INITIALIZER */
    init() {
      /* FLAG STEP AS UNCOMPLETED */
      this.setStepAsCompleted(this, false);

      /* MOVE PROGRESS BAR TO VALUE */
      this.progress(this);

      /* SHOW SIDE AND NAV BAR IF USER FALLS DIRECTLY INTO CURRENT COMPONENT */
      this.$store.dispatch('toggleProgressBar', true);
      this.$store.dispatch('toggleSideBar', true);

      /* STOP LOADING */
      this.$store.dispatch('isLoading', false);
    },
    download() {
      this.$store.dispatch('isLoading', true);

      const token = this.getAccessToken();

      FileDataService.download(token, this.$route.params.file as string)
        .then((response: any) => {
          const saveData = (function () {
            const a = document.createElement('a');
            document.body.appendChild(a);

            return function (data: any, fileName: string) {
              const blob = new Blob([data], { type: 'application/pdf' });
              const url = window.URL.createObjectURL(blob);
              a.href = url;
              a.download = fileName;
              a.click();
              window.URL.revokeObjectURL(url);
            };
          }());

          const now = new Date();

          this.$store.dispatch('isLoading', false);

          saveData(response.data, `demande-financement-bna-${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}-${now.getHours()}-${now.getMinutes()}.pdf`);
        })
        .catch((e: Error) => { /* */ });
    },
    /* LOAD DATA THROUGH APIS */
    load(callback: any) {
      FileDataHelper.prepareComponentData(
        this,
        this.$route.params.file as string,
        callback,
        (e: Error) => { /* */ },
      );
    },
    goToNextStep() {
      /* FLAG STEP AS COMPLETED BEFORE MOVING TO NEXT SCREEN */
      this.setStepAsCompleted(this, true);
      this.setNamedStepAsCompleted(this, 'Home', false);

      /* MOVE TO NEXT SCREEN */
      this.goToNextScreen(this, {
        segment: this.$store.state.selected.segment.pid,
      });
    },
  },
});
